html {
  min-height: 100%;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  min-height: 100%;
  background: #E1E1E1;
  font-family: Poppins, serif;
}

@font-face {
  font-family: 'Poppins';
  src: url("fonts/Poppins-Regular.ttf");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'PoppinsMedium';
  src: url("fonts/Poppins-Medium.ttf");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'PoppinsSemiBold';
  src: url("fonts/Poppins-SemiBold.ttf");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'PoppinsBold';
  src: url("fonts/Poppins-Bold.ttf");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'PoppinsExtraBold';
  src: url("fonts/Poppins-ExtraBold.ttf");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'PoppinsBlack';
  src: url("fonts/Poppins-Black.ttf");
  font-weight: 900;
  font-style: italic;
}

.slick-center>div>div {
  width: 170px;
  height: 170px;
  opacity: 1;
  top: 0;
}